/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS components that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content {
  --background: var(--ion-color-light);
}

ion-card {
  margin: 0 0 1em;
}

ion-card-content ion-list h3 {
  font-size: 1.1em !important;
  font-weight: bold !important;
}

ion-list ion-item, ion-item-group ion-item {
  width: 100%;
}

ion-list ion-item ion-row {
  width: 100%;
}

.main-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.main-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding-top: 3.5em;
  width: 15em;
  height: 100%;
  background-color: var(--ion-color-primary-contrast);
  box-shadow: 1px 0 4px 1px var(--ion-color-medium);
}

.main-content-outer-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 15em;
  max-width: 100%;
}

.main-content-inner-container {
  flex-grow: 1;
  margin: 0.5em;
  height: calc(100% - 1em);
  overflow-y: auto;
}

.mobile-only {
  display: none;
}

.mobile-hide {
  display: block;
}

.djs-container .highlight .djs-outline {
  stroke-width: 2px !important;
  stroke: #08c !important;
}
.djs-container .highlight .djs-visual>:nth-child(1) {
  fill: #bce2f5 !important;
}

@media (max-width: 991px) {
  .main-content-outer-container {
    margin-left: 0;
  }
  .mobile-only {
    display: block;
  }
  .mobile-hide {
    display: none;
  }
}
